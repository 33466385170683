import { getOptionIdByName, emblemErrorShower } from "../utils.js";
import FontTextWrapper from "../Wrapper/FontTextWrapper.js";
import SchärpenSelector from "../components/SchärpenSelector.svelte";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher.js";
const $ = jQuery;
export default function Scharpen() {
    var _a, _b, _c, _d;
    const sizeXs = getOptionIdByName("GRÖßE XS");
    const sizeS = getOptionIdByName("GRÖßE S");
    const sizeM = getOptionIdByName("GRÖßE M");
    const sizeL = getOptionIdByName("GRÖßE L");
    const sizeXl = getOptionIdByName("GRÖßE XL");
    jQuery(".product-options .qty-holder").css("display", "none");
    const sizeElementsRaw = jQuery("#product-options-wrapper > *")
        .get()
        .filter((t) => {
        var _a;
        return [sizeXs, sizeS, sizeM, sizeL, sizeXl]
            .map((t) => t === null || t === void 0 ? void 0 : t.replace("option-", ""))
            .includes(((_a = t.getAttribute("id")) === null || _a === void 0 ? void 0 : _a.replace("option-", "")) || "");
    });
    const sizeElements = sizeElementsRaw.map((t) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            optionId: (_a = t.getAttribute("id")) === null || _a === void 0 ? void 0 : _a.replace("option-", ""),
            options: jQuery(t)
                .find(`#bundle-option-${(_b = t.getAttribute("id")) === null || _b === void 0 ? void 0 : _b.replace("option-", "")}`)
                .children("option")
                .get()
                .map((op) => ({
                value: op.getAttribute("value"),
                color: op.innerText.split(" ")[1],
                option: op,
            }))
                .filter((c) => c.value),
            element: t,
            sizeName: ((_f = (_e = (_d = (_c = jQuery(jQuery(t).find("dt > label").get(0))) === null || _c === void 0 ? void 0 : _c.text()) === null || _d === void 0 ? void 0 : _d.replace(/GRÖßE/gi, "")) === null || _e === void 0 ? void 0 : _e.replace(/GRÖSSE/gi, "")) === null || _f === void 0 ? void 0 : _f.trim()) || "unknown",
        });
    });
    sizeElements.forEach((t) => jQuery(t.element).css("display", "none"));
    function clearValuesOfSizes() {
        for (const size of sizeElements) {
            jQuery(`#bundle-option-${size.optionId}`)
                .val("")
                .trigger("change")
                .trigger("click");
        }
    }
    let selectedSize = ((_a = sizeElements === null || sizeElements === void 0 ? void 0 : sizeElements[0]) === null || _a === void 0 ? void 0 : _a.optionId) || null;
    let selectedColorOption = (_d = (_c = (_b = sizeElements === null || sizeElements === void 0 ? void 0 : sizeElements[0]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.value;
    const schärpenSelector = new SchärpenSelector({
        target: document.querySelector(`#product-options-wrapper`),
        props: {
            sizes: sizeElements,
            selectedSize,
            selectedColorOption,
            onClickSize: (size) => {
                selectedSize = size.optionId;
                render();
            },
            onClickColor: (size) => {
                selectedColorOption = size.value;
                render();
            },
        },
    });
    function render() {
        clearValuesOfSizes();
        schärpenSelector.$set({
            sizes: sizeElements,
            selectedSize,
            selectedColorOption,
            onClickSize: (size) => {
                var _a, _b;
                if (selectedSize != size.optionId) {
                    selectedColorOption = (_b = (_a = size === null || size === void 0 ? void 0 : size.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value;
                }
                selectedSize = size.optionId;
                render();
            },
            onClickColor: (size) => {
                selectedColorOption = size.value;
                render();
            },
        });
        for (const size of sizeElements) {
            if (size.optionId === selectedSize && selectedColorOption) {
                jQuery(`#bundle-option-${size.optionId}`)
                    .val(selectedColorOption)
                    .trigger("change")
                    .trigger("click");
            }
        }
    }
    render();
    const emblemOption = getOptionIdByName("Emblem Wahl");
    const emblemTypeOption = document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("bundle-option-select"))}`);
    const optionValueTextUpload = (s) => s.includes("Eigenes Motiv");
    const optionValueTextEmblems = (s) => s.includes("mm");
    let rerenderEmblemSwitch = emblemTypeOption
        ? EmblemSwitcher([
            {
                option: emblemTypeOption,
                upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
                emblems: document.querySelector(`#${getOptionIdByName("Emblem Wahl", (op) => $(op).hasClass("option-emblem-root"))}`),
            },
        ], optionValueTextUpload, optionValueTextEmblems)
        : null;
    if (emblemOption) {
        emblemErrorShower({
            emblemParentSelect: $(emblemOption)
                .find("select.bundle-option-select")
                .get(0),
            optionValueTextEmblems,
            optionValueTextUpload,
            rerenderEmblemSwitch,
            uploadOption: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
            emblemOption: document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`),
        });
    }
    const befestigung = document.querySelector("#" + getOptionIdByName("BEFESTIGUNG"));
    function r() {
        const optionText = $(befestigung)
            .find("select")
            .find(`option[value="${$(befestigung).find("select").val()}"]`)
            .text();
        if (!optionText.includes("Rosette")) {
            $(emblemTypeOption).find("select").val("").trigger("change");
            $(emblemTypeOption).css("display", "none");
        }
        else {
            $(emblemTypeOption).css("display", "flex");
            $(emblemTypeOption).find("select").val("").trigger("change");
        }
        rerenderEmblemSwitch === null || rerenderEmblemSwitch === void 0 ? void 0 : rerenderEmblemSwitch.render();
    }
    $(befestigung).on("change", ({ target }) => r());
    r();
    jQuery(`#${getOptionIdByName("Befestigung")}`).css("order", "0");
    jQuery(`#${getOptionIdByName("Bedruckung hinten")}`).css("order", "200");
    const c = FontTextWrapper({
        fontOption: document.querySelector(`#${getOptionIdByName("Schriftart")}`),
        textOption: document.querySelector(`#${getOptionIdByName("Bedruckung vorne")}`),
        backTextOption: document.querySelector(`#${getOptionIdByName("Bedruckung hinten")}`),
        fontTypeOption: document.querySelector(`#${getOptionIdByName("Bedruckung")}`),
        target: document.querySelector("#product-options-wrapper"),
        order: 199,
        displayFontTypeOption: true,
        displayByFontTypeOption: {
            frontText: [true, true, true],
            backText: [false, false, true],
        },
        textUpdateCallback: ({ text, fontTypeOption, }) => {
            return text;
        },
    });
    c.render();
}
