export default function FontTextWrapper({ fontOption, fontTypeOption, textOption, backTextOption, target, order, labelOverwrite, subText, displayByFontTypeOption = {
    frontText: [true, true, true],
    backText: [false, false, true],
}, displayFontTypeOption, textUpdateCallback = ({ text, fontTypeOption, }) => {
    var _a;
    let newIndex = 0;
    if (text !== "")
        newIndex = 1;
    if (fontTypeOption.querySelector(".input-box > select")) {
        fontTypeOption.querySelector(".input-box > select").selectedIndex = newIndex;
    }
    const e = new Event("change");
    (_a = fontTypeOption
        .querySelector(".input-box > select")) === null || _a === void 0 ? void 0 : _a.dispatchEvent(e);
    return text;
}, }) {
    if (!fontOption || !fontTypeOption || !textOption) {
        throw new Error("missing option...");
    }
    let thisWrapper = this;
    const id = Math.round(Math.random() * 1000);
    let text = "";
    let showText = false;
    jQuery(fontOption).css("display", "none");
    jQuery(textOption).css("display", "none");
    if (!displayFontTypeOption)
        jQuery(fontTypeOption).css("display", "none");
    const fonts = JSON.parse(jQuery(fontOption).find("select.fonts").attr("data-fonts") || "");
    function clearFromDom() {
        showText = false;
        jQuery(`#${id}`).remove();
        if (backTextOption) {
            jQuery(backTextOption).hide();
            if (backTextOptionTextarea)
                backTextOptionTextarea.value = "";
        }
    }
    function selectFontTypeOption(optionId) {
        const selectEl = fontTypeOption.querySelector(".input-box > select");
        if (!selectEl)
            return;
        if (optionId >= selectEl.options.length)
            optionId = 0;
        selectEl.selectedIndex = optionId;
        return this;
    }
    let backTextOptionTextareaClassClean;
    let backTextOptionTextarea;
    if (backTextOption) {
        backTextOptionTextareaClassClean = "";
        backTextOptionTextarea =
            backTextOption === null || backTextOption === void 0 ? void 0 : backTextOption.querySelector(".input-box > textarea");
        backTextOptionTextarea === null || backTextOptionTextarea === void 0 ? void 0 : backTextOptionTextarea.setAttribute("placeholder", "...wenn Text gewünscht, bitte hier eingeben");
        if (backTextOptionTextarea)
            backTextOptionTextarea.style.fontSize = "25px";
        if (!backTextOptionTextareaClassClean)
            backTextOptionTextareaClassClean =
                (backTextOptionTextarea === null || backTextOptionTextarea === void 0 ? void 0 : backTextOptionTextarea.className) || "";
    }
    jQuery(fontTypeOption)
        .find(".input-box > select")
        .on("change", ({ target }) => {
        render();
    });
    function render() {
        var _a, _b, _c, _d, _e, _f;
        clearFromDom();
        let selectedFont = fonts.find((t) => t.name === jQuery(fontOption).find("select.fonts").val());
        if (!selectedFont) {
            selectedFont = fonts[0];
            jQuery(fontOption)
                .find("select.fonts")
                .val(fonts[0].name)
                .trigger("change");
        }
        let selectedOption = parseInt((_b = String((_a = fontTypeOption.querySelector(".input-box > select")) === null || _a === void 0 ? void 0 : _a.selectedIndex)) !== null && _b !== void 0 ? _b : "0", 10);
        if ((_d = (_c = displayByFontTypeOption.frontText) === null || _c === void 0 ? void 0 : _c[selectedOption]) !== null && _d !== void 0 ? _d : false) {
            showText = true;
        }
        if (((_f = (_e = displayByFontTypeOption.backText) === null || _e === void 0 ? void 0 : _e[selectedOption]) !== null && _f !== void 0 ? _f : false) &&
            backTextOption) {
            jQuery(backTextOption).show();
            if (backTextOptionTextarea)
                backTextOptionTextarea.className =
                    backTextOptionTextareaClassClean +
                        " " +
                        (selectedFont === null || selectedFont === void 0 ? void 0 : selectedFont.classes);
        }
        jQuery(textOption).find("textarea").val(text);
        jQuery(fontOption)
            .find("select.input")
            .val(selectedFont === null || selectedFont === void 0 ? void 0 : selectedFont.name)
            .trigger("change");
        /*
        const textRight = document.querySelector('#svg-text-right');
        const svgTemplate = document.querySelector('#svg-text-template');
        const maxW = 340;
        const baseFontSize = 3400;

        textRight.innerHTML = svgTemplate.innerHTML = text;
        svgTemplate.setAttribute('font-size', baseFontSize.toString());
        svgTemplate.setAttribute('class', selectedFont?.classes );
        textRight.setAttribute('font-size', baseFontSize.toString());
        textRight.setAttribute('class', selectedFont?.classes );
        const dimensions = svgTemplate.getBoundingClientRect();
        const myw = dimensions.width;
        const newFontSize = maxW / (myw/ baseFontSize);
        console.log('----');
        console.log(maxW);
        console.log(baseFontSize);
        console.log(dimensions);
        console.log(myw);
        console.log(newFontSize);
        console.log('----');

        textRight.setAttribute('font-size', Math.min(newFontSize, baseFontSize).toString());
        textRight.setAttribute('style', 'transform:translate(10420px, 6000px) rotate(80deg);');
        //textRight.setAttribute('style', 'transform:translate(10420px, 6000px);');
        console.log(textRight);
        */
        jQuery(target).prepend(`
    <div id="${id}" style="margin-bottom: 1em;margin-top: 1.5em;${order ? "order: " + order : ""};${showText ? "" : "display: none;"};">
        <h2 style="width: 100%;border: 1px solid #efefef;border-bottom: 0;padding: 0.5em 0.5em 0.5em 0.5em;margin-bottom: 0;text-transform: uppercase;
        color: #21293c;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: .005em;">
            ${labelOverwrite
            ? labelOverwrite
            : jQuery(textOption).find("dt > label").text()}
        </h2>
        <div style="display: flex;flex-direction: row;border: 1px solid #efefef">
            <textarea maxlength="50" 
            placeholder="...wenn Text gewünscht, bitte hier eingeben" 
            style="min-height: 150px;width: 50%;resize: none;font-size: 25px" 
            id="${id}-textarea" 
            class="${selectedFont === null || selectedFont === void 0 ? void 0 : selectedFont.classes}" />
            <div style="width: 50%;display: flex;flex-direction: column;padding: 0.5em;gap: 0.5em">
                <label style="font-size: 1.2rem">
                    Schriftart:
                </label>
                <select id="${id}-select-font">
                    ${fonts
            .map((t) => `
                        <option ${t.name === (selectedFont === null || selectedFont === void 0 ? void 0 : selectedFont.name) ? "selected" : ""} value="${t.name}">
                            ${t.name}
                        </option>
                    `)
            .join("")}
                </select>
                <label style="font-size: 1rem;margin-top: 0.5em">
                      ${subText
            ? subText
            : `Standard-Schriftfarbe: Schwarz
                      <br/>
                      (Silber & Gold nur auf Anfrage)`}
                </label>
            </div>
        </div>
        Maximale Anzahl von Ziffern oder Zeichen: 50

    </div>
        `);
        jQuery(`#${id}-textarea`).val(text);
        jQuery(`#${id}-textarea`).on("blur", ({ target }) => {
            text = textUpdateCallback({
                text: jQuery(target).val(),
                fontTypeOption: fontTypeOption,
            });
            render();
        });
        jQuery(`#${id}-select-font`).on("change", ({ target: { value } }) => {
            jQuery(fontOption)
                .find("select.fonts")
                .val(value)
                .trigger("change");
            render();
        });
        return this;
    }
    return {
        render,
        clearFromDom,
        selectFontTypeOption,
    };
}
