export const idMap = {
    A: {
        "#_1698455568528": {
            type: "ring",
        },
        "#_1698455568208 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_1698455568208 > polygon:nth-child(2)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_1698455553424",
        },
        "#_1698455553424": {
            type: "emblem",
        },
    },
    B: {
        "#_2467041771888": {
            type: "ring",
        },
        "#_2467041774544": {
            type: "ring",
        },
        "#_2467043443952 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467043443952 > polygon:nth-child(2)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467043432048",
        },
        "#_2467043432048": {
            type: "emblem",
        },
    },
    C: {
        "#_2466987155984": {
            type: "ring",
        },
        "#_2467045586672": {
            type: "ring",
        },
        "#_2467045575056": {
            type: "ring",
        },
        "#_2467045576112 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467045576112 > polygon:nth-child(2)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467045563152",
        },
        "#_2467045563152": {
            type: "emblem",
        },
    },
    D: {
        "#_2467044194496": {
            type: "ring",
        },
        "#_2467044183552": {
            type: "ring",
        },
        "#_2467045588144": {
            type: "ring",
        },
        "#_2467045577264": {
            type: "ring",
        },
        "#_2467045575440 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467045575440 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467045575440 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467045561936",
        },
        "#_2467045561936": {
            type: "emblem",
        },
    },
    E: {
        "#_2466983917632": {
            type: "ring",
        },
        "#_2467034215088": {
            type: "ring",
        },
        "#_2467049250064": {
            type: "ring",
        },
        "#_2466924272768": {
            type: "ring",
        },
        "#_2467041874528": {
            type: "ring",
        },
        "#_2467041870976 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467041870976 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467041870976 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467041875040",
        },
        "#_2467041875040": {
            type: "emblem",
        },
    },
    F: {
        "#_2467045583984": {
            type: "ring",
        },
        "#_2467045571504": {
            type: "ring",
        },
        "#_2467045572112 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467045572112 > polygon:nth-child(2)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467045557264",
        },
        "#_2467045557264": {
            type: "emblem",
        },
    },
    G: {
        "#_2467047371760": {
            type: "ring",
        },
        "#_2467047361648": {
            type: "ring",
        },
        "#_2467047361104 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467047361104 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467047361104 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467047349232",
        },
        "#_2467047349232": {
            type: "emblem",
        },
    },
    H: {
        "#_2467044908256": {
            type: "ring",
        },
        "#_2467087897408": {
            type: "ring",
        },
        "#_2467044908864 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467044908864 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467044908864 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467044912256",
        },
        "#_2467044912256": {
            type: "emblem",
        },
    },
    I: {
        "#_2467049466528": {
            type: "ring",
        },
        "#_2467087119424": {
            type: "ring",
        },
        "#_2467087105792": {
            type: "ring",
        },
        "#_2467087106688 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467087106688 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467087106688 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467087092352",
        },
        "#_2467087092352": {
            type: "emblem",
        },
    },
    J: {
        "#_2467045580976": {
            type: "ring",
        },
        "#_2467045568176": {
            type: "ring",
        },
        "#_2466987154192": {
            type: "ring",
        },
        "#_2466987152464 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2466987152464 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2466987152464 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2466987158416",
        },
        "#_2466987158416": {
            type: "emblem",
        },
    },
    K: {
        "#_2467086895504": {
            type: "ring",
        },
        "#_2467087911584": {
            type: "ring",
        },
        "#_2467087902752": {
            type: "ring",
        },
        "#_2467087901536 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467087901536 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467087901536 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467087923168",
        },
        "#_2467087923168": {
            type: "emblem",
        },
    },
    L: {
        "#_2467047350576": {
            type: "ring",
        },
        "#_2467047406064": {
            type: "ring",
        },
        "#_2467046542544": {
            type: "ring",
        },
        "#_2467046567952": {
            type: "ring",
        },
        "#_2466924300192 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2466924300192 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2466924300192 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467046548496",
        },
        "#_2467046548496": {
            type: "emblem",
        },
    },
    M: {
        "#_2467050327744": {
            type: "ring",
        },
        "#_2467050325600 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467050325600 > polygon:nth-child(2)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467050315616",
        },
        "#_2467050315616": {
            type: "emblem",
        },
    },
    N: {
        "#_2467046600208": {
            type: "ring",
        },
        "#_2467046555184": {
            type: "ring",
        },
        "#_2467046553808 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467046553808 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467046553808 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467046540592",
        },
        "#_2467046540592": {
            type: "emblem",
        },
    },
    O: {
        "#_2467092833888": {
            type: "ring",
        },
        "#_2467092823488": {
            type: "ring",
        },
        "#_2467092820512 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467092820512 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467092820512 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467092841376",
        },
        "#_2467092841376": {
            type: "emblem",
        },
    },
    P: {
        "#_2467050369344": {
            type: "ring",
        },
        "#_2467050359520": {
            type: "ring",
        },
        "#_2467050350048": {
            type: "ring",
        },
        "#_2467050349408 > polygon:nth-child(2)": {
            type: "band",
        },
        "#_2467050349408 > polygon:nth-child(1)": {
            type: "band",
        },
        "#_2467050349408 > polygon:nth-child(3)": {
            type: "band",
        },
        "#Ebene_x0020_1 > .fil6": {
            type: "emblem",
            rewriteKey: "#_2467050337088",
        },
        "#_2467050337088": {
            type: "emblem",
        },
    },
};
