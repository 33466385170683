import config from "../config/index.js";
import OptionEmblem from "../option/emblem/OptionEmblem.js";
import OptionFont from "../option/font/OptionFont.js";
import "../option/OptionAbstract.js";
import OptionColor from "../option/ring/OptionColor.js";
import { idMap } from "../config/turnierschleife.js";
import { emblemErrorShower, getOptionIdByName } from "../utils.js";
import FontTextWrapper from "../Wrapper/FontTextWrapper.js";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher.js";
const $ = jQuery;
function loadOptions(root) {
    const options = Array.from(root.querySelectorAll(".fleno-option-root"));
    const instances = options
        .map((o) => {
        const optionsRoot = {
            emblem: OptionEmblem,
            color: OptionColor,
            font: OptionFont,
        };
        const type = o.getAttribute("data-type");
        if (type && optionsRoot[type]) {
            return new optionsRoot[type](o);
        }
        return null;
    })
        .filter(Boolean);
    return instances;
}
const getIndexOfType = (t, key) => {
    if (t[key].rewriteKey) {
        key = t[key].rewriteKey;
    }
    const type = t[key].type;
    //@ts-expect-error
    const ofType = Object.entries(t).filter(([_, c]) => c.type === type);
    return ofType.findIndex(([k]) => k === key);
};
export default async function Turnierschleife() {
    var _a;
    const root = document.querySelector("#product-options-wrapper");
    if (!root) {
        return;
    }
    $(".product-options .qty-holder").css("display", "none");
    const middleOptions = {
        fontTypeOption: document.querySelector(`#${getOptionIdByName("BEDRUCKUNG (MITTE)")}`),
        fontOption: document.querySelector(`#${getOptionIdByName("Schriftart (mitte)")}`),
        textOption: document.querySelector(`#${getOptionIdByName("BESCHRIFTUNG (MITTE)")}`),
        target: document.querySelector("#product-options-wrapper"),
        subText: ((_a = document
            .querySelector(`#${getOptionIdByName("Bedruckung (mitte)")}`)) === null || _a === void 0 ? void 0 : _a.querySelectorAll("option"))
            ? [
                ...document
                    .querySelector(`#${getOptionIdByName("Bedruckung (mitte)")}`)
                    .querySelectorAll("option"),
            ]
                .filter((t) => $(t).attr("value"))
                .map((t) => t.textContent)
                .join(",")
            : null,
    };
    const rightOptions = {
        fontTypeOption: document.querySelector(`#${getOptionIdByName("BEDRUCKUNG (RECHTS)")}`),
        fontOption: document.querySelector(`#${getOptionIdByName("Schriftart (rechts)")}`),
        textOption: document.querySelector(`#${getOptionIdByName("BESCHRIFTUNG (RECHTS)")}`),
        target: document.querySelector("#product-options-wrapper"),
        subText: [
            ...document
                .querySelector(`#${getOptionIdByName("Bedruckung (rechts)")}`)
                .querySelectorAll("option"),
        ]
            .filter((t) => $(t).attr("value"))
            .map((t) => t.textContent)
            .join(","),
    };
    const leftOptions = {
        fontTypeOption: document.querySelector(`#${getOptionIdByName("BEDRUCKUNG (LINKS)")}`),
        fontOption: document.querySelector(`#${getOptionIdByName("Schriftart (links)")}`),
        textOption: document.querySelector(`#${getOptionIdByName("BESCHRIFTUNG (LINKS)")}`),
        target: document.querySelector("#product-options-wrapper"),
        subText: [
            ...document
                .querySelector(`#${getOptionIdByName("Bedruckung (links)")}`)
                .querySelectorAll("option"),
        ]
            .filter((t) => $(t).attr("value"))
            .map((t) => t.textContent)
            .join(","),
    };
    const rendererMiddleText = Object.values(middleOptions).filter(Boolean).length ===
        Object.values(middleOptions).length
        ? FontTextWrapper(middleOptions)
        : null;
    const rendererRightText = Object.values(rightOptions).filter(Boolean).length ===
        Object.values(rightOptions).length
        ? FontTextWrapper(rightOptions)
        : null;
    const rendererLeftText = Object.values(leftOptions).filter(Boolean).length ===
        Object.values(leftOptions).length
        ? FontTextWrapper(leftOptions)
        : null;
    const options = loadOptions(root);
    const ribbonType = (window.ribbonType || "A");
    $(config.productImageSelector).children().remove();
    const svg = `/skin/frontend/smartwave/porto/images/configurator-v2/Turnierschleifen/${ribbonType}.svg`;
    const svgElement = $(await fetch(svg).then((t) => t.text())).last();
    svgElement.css("height", "820px").css("width", "500px");
    $(config.productImageSelector)
        .append(svgElement)
        .css("display", "flex")
        .css("justify-content", "center")
        .css("cursor", "pointer");
    $("#product-options-wrapper > *").css("display", "none");
    $(".product-shop.col-md-7").css("max-width", "unset").css("flex-grow", 1);
    $(".product-view.custom").css("width", "100%");
    const infoText = `<div style="width: 100%;padding: 1em;border-bottom: 1px solid #efefef">
    <h3 style="color: black">
        Bitte klicken Sie auf den Bereich im Bild, den Sie anpassen möchten.
    </h3>
    <h4 style="font-style: italic;color: red">
    Bitte beachten Sie, dass die Schleife hier nur skizzenhaft dargestellt wird und das Endprodukt leicht abweichen kann.
    </h4>
  
    </div>`;
    if ($(document).width() > 768) {
        $(".product-options-wrapper-configurator").prepend(infoText);
    }
    else {
        $(".product-essential > form  > .row").prepend(infoText);
        $(".product-essential > form  > .row").prepend($(".product-shop.col-md-7 > .product-name"));
    }
    function replaceCss(css) {
        $("#configuratorShow").remove();
        $("body").append('<style id="configuratorShow">' + css + "</style>");
    }
    const svgKeyToOption = (key, payload) => {
        var _a, _b;
        const index = getIndexOfType(idMap[ribbonType], key);
        if (payload.type === "band") {
            const colorOption = (_a = options
                .filter((t) => t.getType() === "color")
                .filter((t) => ["links", "mittig", "rechts"].includes(t.getName().toLowerCase()))) === null || _a === void 0 ? void 0 : _a[index];
            if (colorOption) {
                return colorOption;
            }
        }
        else if (payload.type === "ring") {
            const colorOptions = options
                .filter((t) => t.getType() === "color")
                .filter((t) => t.getName().toLowerCase().startsWith("ring"));
            // order is reversed in svg
            const colorOption = colorOptions[colorOptions.length - index - 1];
            if (colorOption) {
                return colorOption;
            }
        }
        else if (payload.type === "emblem") {
            const emblemType = (_b = options.filter((t) => t.getType() === "emblem")) === null || _b === void 0 ? void 0 : _b[0];
            if (emblemType) {
                return emblemType;
            }
        }
    };
    const updateImage = () => {
        for (let [key, payload] of Object.entries(idMap[ribbonType])) {
            if (payload.rewriteKey) {
                key = payload.rewriteKey;
            }
            const colorOption = svgKeyToOption(key, payload);
            if (payload.type === "band") {
                if (colorOption) {
                    svgElement.find(key).css("fill", colorOption.getHexColor());
                    colorOption.getValue();
                }
            }
            else if (payload.type === "ring") {
                svgElement
                    .find(key)
                    .children()
                    .css("fill", colorOption.getHexColor());
                colorOption.getValue();
            }
            else if (payload.type === "emblem") {
                if (colorOption.getImage()) {
                    svgElement.find("#emblem-in-svg").remove();
                    //.fill6 ist only the class of letter
                    svgElement.find(".fil6").remove();
                    const c = document.createElementNS("http://www.w3.org/2000/svg", "image");
                    c.setAttribute("width", "5200");
                    c.setAttribute("height", "5200");
                    c.setAttribute("x", "8200");
                    c.setAttribute("y", "-620");
                    c.setAttribute("id", "emblem-in-svg");
                    c.setAttribute("href", colorOption.getImage());
                    c.onclick = () => $(svgElement.find(key)).trigger("click");
                    svgElement.get(0).append(c);
                }
            }
        }
    };
    const emblemParentSelectRoot = $(document.querySelector(`#${getOptionIdByName("EMBLEM", (op) => $(op).hasClass("bundle-option-select"))}`));
    const emblemParentSelect = $(emblemParentSelectRoot)
        .find("select.bundle-option-select")
        .get(0);
    updateImage();
    for (let [key, payload] of Object.entries(idMap[ribbonType])) {
        svgElement.find(key).on("click", () => {
            var _a, _b, _c;
            if (payload.rewriteKey) {
                key = payload.rewriteKey;
            }
            const colorOption = svgKeyToOption(key, payload);
            $("#product-options-wrapper > *").css("display", "none");
            $(options.map((t) => t.element)).css("display", "none");
            if (payload.type === "band") {
                $(colorOption.element).css("display", "flex");
                rendererMiddleText === null || rendererMiddleText === void 0 ? void 0 : rendererMiddleText.clearFromDom();
                rendererRightText === null || rendererRightText === void 0 ? void 0 : rendererRightText.clearFromDom();
                rendererLeftText === null || rendererLeftText === void 0 ? void 0 : rendererLeftText.clearFromDom();
                replaceCss("");
                if ((_a = colorOption
                    .getName()) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("links")) {
                    rendererLeftText === null || rendererLeftText === void 0 ? void 0 : rendererLeftText.render();
                    replaceCss(`${key} {
            stroke-width: 200px;
          }`);
                }
                else if ((_b = colorOption
                    .getName()) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes("mittig")) {
                    rendererMiddleText === null || rendererMiddleText === void 0 ? void 0 : rendererMiddleText.render();
                    replaceCss(`${key} {
            stroke-width: 200px;
          }`);
                }
                else if ((_c = colorOption
                    .getName()) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes("rechts")) {
                    rendererRightText === null || rendererRightText === void 0 ? void 0 : rendererRightText.render();
                    replaceCss(`${key} {
            stroke-width: 200px;
          }`);
                }
            }
            else if (payload.type === "ring") {
                $(colorOption.element).css("display", "flex");
                replaceCss(`${key} > ellipse {
          stroke-width: 200px;
        }`);
            }
            else if (payload.type === "emblem") {
                $(emblemParentSelectRoot).css("display", "flex");
            }
            productOptionConnditions();
        });
    }
    // Update images after option has changed
    $(root)
        .parent()
        .find("input")
        .on("change", () => window.requestAnimationFrame(() => updateImage()));
    const optionValueTextUpload2 = (s) => s.includes("Sublimationsemblem");
    const optionValueTextUpload = (s) => s.includes("Eigenes Motiv") || optionValueTextUpload2(s);
    const optionValueTextEmblems = (s) => s.includes("mm");
    let rerenderEmblemSwitch = document.querySelector(`#${getOptionIdByName("EMBLEM")}`)
        ? EmblemSwitcher([
            {
                option: document.querySelector(`#${getOptionIdByName("EMBLEM", (op) => $(op).hasClass("bundle-option-select"))}`),
                upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
                emblems: document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`),
            },
        ], optionValueTextUpload, optionValueTextEmblems, optionValueTextUpload2)
        : null;
    if (emblemParentSelect) {
        emblemErrorShower({
            emblemParentSelect,
            optionValueTextEmblems,
            optionValueTextUpload,
            rerenderEmblemSwitch,
            uploadOption: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
            emblemOption: document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`),
        });
    }
    function productOptionConnditions() {
        if ($(emblemParentSelectRoot).css("display") === "flex") {
            rerenderEmblemSwitch === null || rerenderEmblemSwitch === void 0 ? void 0 : rerenderEmblemSwitch.render();
        }
        // DISPLAY FREETEXT
        [...$("#product-options-wrapper .text-option dt label").get()].forEach((t) => {
            if ($(t).text().trim().toLowerCase().includes("kommentarfeld")) {
                $(t).parent().parent().css("display", "");
                $(t).parent().parent().css("padding-top", "1em");
                $(t).parent().parent().css("margin-top", "1.5em");
                $(t)
                    .parent()
                    .parent()
                    .css("border-top", "1px solid #efefef");
                $(t).parent().parent().find("dd").css("width", "100%");
                $(t)
                    .parent()
                    .parent()
                    .find("textarea")
                    .attr("placeholder", "Zum Beispiel goldene Beschriftung, längeres Schleifenband o.ä.");
            }
        });
        /*
    // left font
    if (
      jQuery("#bundle-option-739").val() === "5773" &&
      jQuery("#option-739").css("display") !== "none"
    ) {
      jQuery("#option_1149").css("display", "");
      jQuery("#option-1150").css("display", "");
    } else {
      jQuery("#option_1149").css("display", "none");
      jQuery("#option-1150").css("display", "none");
    }

    // middle font
    if (
      jQuery("#bundle-option-740").val() === "5776" &&
      jQuery("#option-740").css("display") !== "none"
    ) {
      jQuery("#option_1155").css("display", "");
      jQuery("#option-1153").css("display", "");
    } else {
      jQuery("#option_1155").css("display", "none");
      jQuery("#option-1153").css("display", "none");
    }

    // right font
    if (
      jQuery("#bundle-option-741").val() === "5779" &&
      jQuery("#option-741").css("display") !== "none"
    ) {
      jQuery("#option_1154").css("display", "");
      jQuery("#option-1152").css("display", "");
    } else {
      jQuery("#option_1154").css("display", "none");
      jQuery("#option-1152").css("display", "none");
    }*/
    }
    $(".product-options-wrapper-configurator").on("change", () => requestAnimationFrame(() => productOptionConnditions()));
    productOptionConnditions();
}
