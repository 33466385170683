import { emblemErrorShower, getOptionIdByName } from "../utils";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher";
import FontTextWrapper from "../Wrapper/FontTextWrapper";
const $ = jQuery;
export default function Plakette() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const fontTypeOption = (_c = (_b = (_a = document.querySelector(`#${getOptionIdByName("GRAVUR", (op) => $(op).hasClass("bundle-option-select"))}`)) !== null && _a !== void 0 ? _a : document.querySelector(`#${getOptionIdByName("LASERGRAVUR", (op) => $(op).hasClass("bundle-option-select"))}`)) !== null && _b !== void 0 ? _b : document.querySelector(`#${getOptionIdByName("BESCHRIFTUNG", (op) => $(op).hasClass("bundle-option-select"))}`)) !== null && _c !== void 0 ? _c : document.querySelector(`#${getOptionIdByName("Bedruckung", (op) => $(op).hasClass("bundle-option-select"))}`);
    const fontOption = (_d = document.querySelector(`#${getOptionIdByName("Schrift Wahl")}`)) !== null && _d !== void 0 ? _d : document.querySelector(`#${getOptionIdByName("Schriftart")}`);
    const textOption = (_h = (_g = (_f = (_e = document.querySelector(`#${getOptionIdByName("TEXTGRAVUR AUF EINER PLAKETTE")}`)) !== null && _e !== void 0 ? _e : document.querySelector(`#${getOptionIdByName("TEXTGRAVUR AUF PLAKETTE")}`)) !== null && _f !== void 0 ? _f : document.querySelector(`#${getOptionIdByName("GRAVUR", (op) => $(op).hasClass("text-option"))}`)) !== null && _g !== void 0 ? _g : document.querySelector(`#${getOptionIdByName("LASERGRAVUR", (op) => $(op).hasClass("text-option"))}`)) !== null && _h !== void 0 ? _h : document.querySelector(`#${getOptionIdByName("Text", (op) => $(op).hasClass("text-option"))}`);
    if (fontTypeOption && fontOption && textOption) {
        const c = FontTextWrapper({
            fontOption,
            textOption,
            fontTypeOption,
            target: document.querySelector("#product-options-wrapper"),
            order: 145,
            // labelOverwrite: "TEXT",
            subText: [...fontTypeOption.querySelectorAll("option")]
                .filter((t) => $(t).attr("value"))
                .map((t) => t.textContent)
                .join(","),
        });
        c.render();
    }
    const emblemTypeOption = document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("bundle-option-select"))}`);
    const emblemOption = (_j = document.querySelector(`#${getOptionIdByName("Emblem Wahl", (op) => $(op).hasClass("option-emblem-root"))}`)) !== null && _j !== void 0 ? _j : document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`);
    if (emblemTypeOption && emblemOption) {
        const optionValueTextUpload = (s) => s.includes("Eigenes Motiv");
        const optionValueTextEmblems = (s) => s.includes("mm");
        const rerenderEmblemSwitch = EmblemSwitcher([
            {
                option: emblemTypeOption,
                upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
                emblems: emblemOption,
            },
        ]);
        emblemErrorShower({
            emblemParentSelect: $(emblemTypeOption)
                .find("select.bundle-option-select")
                .get(0),
            optionValueTextEmblems,
            optionValueTextUpload,
            rerenderEmblemSwitch,
            uploadOption: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
            emblemOption,
        });
    }
}
