export default {
    productOptionSelector: "div.product-options",
    productIdSelector: 'input[name="product"]',
    enabledProducts: [5175],
    productImageSelector: ".product-img-box",
    tapeColorMap: {
        70: "left",
        71: "right",
        72: "center",
    },
    ringColorOptionNumberMap: {
        60: 1,
        61: 2,
        62: 3,
        63: 4,
        64: 5,
    },
    colorMap: [
        {
            match: "010",
            color: "#FFFAFA",
        },
        {
            match: "021",
            color: "#FFFF00",
        },
        {
            match: "045",
            color: "#1E90FF",
        },
        {
            match: "067",
            color: "#A52A2A",
        },
        {
            match: "302",
            color: "#32CD32",
        },
        {
            match: "012",
            color: "#FAFAD2",
        },
        {
            match: "025",
            color: "#EEC900",
        },
        {
            match: "047",
            color: "#FF83FA",
        },
        {
            match: "070",
            color: "#000000",
        },
        {
            match: "382",
            color: "#228B22",
        },
        {
            match: "013",
            color: "#E5E5E5",
        },
        {
            match: "042",
            color: "#0000CD",
        },
        {
            match: "050",
            color: "#FF0000",
        },
        {
            match: "242",
            color: "#FF8C00",
        },
        {
            match: "482",
            color: "#9932CC",
        },
        {
            match: "020",
            color: "#EEEE00",
        },
        {
            match: "043",
            color: "#87CEFA",
        },
        {
            match: "059",
            color: "#8B0000",
        },
        {
            match: "271",
            color: "#FFA54F",
        },
        {
            match: "513",
            color: "#FFC0CB",
        },
        {
            match: "gold",
            color: "#EEC900",
        },
        {
            match: "silber",
            color: "#E5E5E5",
        },
    ],
};
