import { emblemErrorShower, getOptionIdByName } from "../utils";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher";
import FontTextWrapper from "../Wrapper/FontTextWrapper";
import PokalSelect from "../components/PokalSelect.svelte";
const $ = jQuery;
export default function Figuren() {
    var _a, _b;
    const option = getOptionIdByName("BESCHRIFTUNG");
    const fontTypeOption = document.querySelector('#' + option);
    const options = fontTypeOption.querySelectorAll('select option');
    let subText = `Textgravur auf Sockel +`;
    for (const opt of options) {
        if (!opt.getAttribute('data-price_currency_symbol'))
            continue;
        if (opt.getAttribute('data-price_including_tax_formatted'))
            subText += opt.getAttribute('data-price_including_tax_formatted');
        else
            subText += opt.getAttribute('data-price_formatted');
        subText += opt.getAttribute('data-price_currency_symbol');
    }
    const c = FontTextWrapper({
        fontOption: document.querySelector(`#${getOptionIdByName("Schrift Wahl")}`),
        textOption: document.querySelector(`#${(_a = getOptionIdByName("TEXTGRAVUR (AUF EINER PLAKETTE)")) !== null && _a !== void 0 ? _a : getOptionIdByName("TEXTGRAVUR")}`),
        fontTypeOption: document.querySelector(`#${getOptionIdByName("BESCHRIFTUNG")}`),
        target: document.querySelector("#product-options-wrapper"),
        order: 145,
        labelOverwrite: "TEXTGRAVUR",
        subText: subText,
    });
    c.render();
    const optionValueTextUpload = (s) => s.includes("Eigenes Motiv");
    const optionValueTextEmblems = (s) => s.includes("mm");
    const emblemParentSelectRoot = $(document.querySelector(`#${getOptionIdByName("EMBLEM", (op) => $(op).hasClass("bundle-option-select"))}`));
    const emblemParentSelect = $(emblemParentSelectRoot)
        .find("select.bundle-option-select")
        .get(0);
    let rerenderEmblemSwitch = document.querySelector(`#${getOptionIdByName("EMBLEM")}`)
        ? EmblemSwitcher([
            {
                option: document.querySelector(`#${getOptionIdByName("EMBLEM", (op) => $(op).hasClass("bundle-option-select"))}`),
                upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
                emblems: document.querySelector(`#${getOptionIdByName("Emblem Wahl", (op) => $(op).hasClass("option-emblem-root"))}`),
            },
        ], optionValueTextUpload, optionValueTextEmblems)
        : null;
    rerenderEmblemSwitch === null || rerenderEmblemSwitch === void 0 ? void 0 : rerenderEmblemSwitch.render();
    if (emblemParentSelect) {
        emblemErrorShower({
            emblemParentSelect,
            optionValueTextEmblems,
            optionValueTextUpload,
            rerenderEmblemSwitch,
            uploadOption: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
            emblemOption: document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`),
        });
    }
    const pokalOptionId = getOptionIdByName("AUSWAHL");
    if (pokalOptionId) {
        const pokalSelectId = "A" + (Math.random() * 100000).toFixed(0);
        $("#product-options-wrapper").append($(`<div style="order: 0" id='${pokalSelectId}'></div>`));
        new PokalSelect({
            target: document.querySelector(`#${pokalSelectId}`),
            props: {
                onSelect: (val) => $(`#${pokalOptionId} .bundle-option-select`)
                    .val(val)
                    .trigger("change")
                    .trigger("click"),
                pokals: [...$(`#${pokalOptionId} option`).get()]
                    .map((t) => ({
                    value: $(t).attr("value"),
                    name: $(t).attr("data-name"),
                    hasEmblem: $(t).attr("data-emblem_size_value")
                        ? true
                        : false,
                    height: $(t).attr("data-height_value"),
                    price: $(t).attr("data-price_lowest"),
                }))
                    .filter((t) => t.value),
                getValue: () => $(`#${pokalOptionId} .bundle-option-select`).val(),
                title: "AUSWAHL",
            },
        });
        $(`#${pokalOptionId}`).css("display", "none");
    }
    $(`#${(_b = getOptionIdByName("Gestaltungswunsch")) !== null && _b !== void 0 ? _b : getOptionIdByName("Kommentarfeld")}`).css("display", "none");
}
