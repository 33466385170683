<script lang="ts">
    export let title: string;
    export let colors: Array<{
        name: string;
        color: string;
        option: Element;
        value: string;
        price: string;
    }>;
    let groups: string[] = [];
    $: groups = [...new Set(colors.map((t) => t.name))];

    let selectedValue: undefined | string;

    $: {
        jQuery(colors[0]?.option).parent().val(selectedValue).trigger("change");
    }

    let tmpName = `A${Math.random() * 1000}`;

    let selectedGroup: string | undefined = undefined;
</script>

<div
    class="root"
    style="order: 20;display: flex;flex-direction: column;margin: 0.5em 0 0.5em 0"
>
    <dt>
        <label>{title}</label>
    </dt>
    <div style="display: flex;flex-direction: row;gap: 0.5em;">
        {#each groups as group}
            <div style="display: flex;flex-direction: row;position: relative;">
                <input
                    on:click={() => {
                        if (selectedGroup !== group) {
                            selectedGroup = group;
                        } else {
                            selectedGroup = "";
                        }
                        selectedValue = "";
                    }}
                    type="radio"
                    id={group}
                    name={tmpName}
                    value={group}
                    checked={selectedGroup === group}
                />
                <label
                    style="display: flex;flex-direction: column;cursor: pointer"
                    for={group}
                >
                    <p style="text-align: center;margin: 0;font-size: 1.2rem">
                        {group}
                    </p>
                    {#if group.includes("kordel")}
                        <img
                            style="width: 150px;user-select: none;"
                            alt=""
                            src="/skin/frontend/smartwave/porto/images/configurator-v2/Kordeln.jpg"
                        />
                    {:else}
                        <img
                            style="width: 150px;user-select: none;"
                            alt=""
                            src="/skin/frontend/smartwave/porto/images/configurator-v2/Bänder.jpg"
                        />
                    {/if}
                </label>
                {#if selectedGroup === group}
                    <button
                        on:click={() => {
                            selectedGroup = "";
                            selectedValue = "";
                        }}
                        style="position: absolute;top: 0;right: 0;border: 0;margin: 0;padding: 0;background-color: white;cursor: pointer;border: 1px solid #efefef;padding: 0.15em;border-radius: 50%;color: red"
                        >X</button
                    >
                {/if}
            </div>
        {/each}
    </div>
    {#if selectedGroup}
        <div style="display: flex;flex-direction: column;margin-top: 0.5em">
            <!-- svelte-ignore a11y-no-onchange -->
            <select
                style="border: 1px solid red"
                bind:value={selectedValue}
                on:change={({ currentTarget: { value } }) => {
                    selectedValue = value;
                }}
            >
                <option value={""}>Bitte treffen Sie eine Auswahl …</option>

                {#each colors.filter((t) => t.name === selectedGroup) as groupItem}
                    <option value={groupItem.value}>
                        {groupItem.color} +{groupItem.price.replace(".", ",")} €
                    </option>
                {/each}
            </select>
        </div>
    {/if}

    <!-- {#each groups as group}
        <div
            style="display: flex;flex-wrap: wrap;row;gap: 0.5em;justify-content: space-start;"
        >
            {#each colors.filter((t) => t.name === group) as groupItem}
                <div
                    on:click={() =>
                        selectedValue === groupItem.value
                            ? (selectedValue = undefined)
                            : (selectedValue = groupItem.value)}
                    class="item-select"
                    style={selectedValue === groupItem.value
                        ? "border-color: green"
                        : ""}
                >
                    <img
                        style="width: 100%;"
                        alt=""
                        src="https://lh3.googleusercontent.com/14R0lPV2tLoY3-dkf2tEVB8YtGZUlBsx4MUOkGI352qGw0vVDXdBplBPWFPPWm-6KfjgQi3zd8SRtS06oEAMXywZ9ZqWsiRPBdJsbMwDgs2-ds4NjAX9o11ENZrnAVxkiHxPkI928YGmaGBBe90VAxEO8TmiVzCFgF9Bg1ukkOCf5RcpkT-rmDV6AEDZpGh24mEu6xPUckE-YnQJs-fKlpYikGoTDUqMqiByzkf_Mw_9wEA8HMEnt1j1KiLcY7StiU-Pi-L3C-D3b6QpXfrBInu18Ajq64WCPJYcNf-qanI1xIU26EduImZXlusDVt6auOKcXX9Zf3i0f5helj-2-yV5XBhCOwy32TIUZkR3VqFkmqRkU9XhtJvdER32QolXZINMwLOmMv58P_BL6hiP2o6nDbNV1GrfsJo6zhQjOF9dWYNoHal-TPuBCbR1QfSo2Gli3BsLTiLIeilONyjDwMjO7I4y6tGnRxPLVpv3yFOyUTkR1rtM84d3EY1Y5vGpQ6FNezPLxvRQTaOY1M55ipddn_ak3UOj5R60wmTwu9hL3VlnWmiYb03x1i_sHg13h9ou1-qDhebRbuhN68iXfOg2mGjK0WsVQNwzJZzJ_KV-BVslCV0rRWJei3qOzAaCu66ixwDRYKmmlOUDQK8xcSfCw3Oyyff50CitY_mtra9Co3_ejNgbwH0cbsHvMspn8HjQnwqUuoFPfUwnVYEaSO77Cg=w732-h976-no?authuser=0"
                    />
                    <p style="text-align: center;margin: auto">
                        {groupItem.color}
                    </p>
                </div>
            {/each}
        </div>
    {/each} -->
</div>

<style>
    .item-select {
        flex-basis: 20%;
        flex-grow: 0;
        flex-shrink: 0;
        cursor: pointer;
        border: 3px solid transparent;
    }
    .item-select:hover {
        border: 3px solid black;
    }
</style>
