<script lang="ts">
    export let pokals: Array<{
        name: string;
        value: string;
        height: string;
        hasEmblem: string;
        price: string;
    }> = [];
    export let onSelect: (vl: string) => void;
    export let getValue: () => string;
    export let onEmblemShowState: (active: boolean) => void = () => null;
    export let title = "Höhe des Pokals";

    let maxHeight = 0;
    $: maxHeight = pokals.reduce((prev, next) => {
        return parseInt(next.height, 10) > prev
            ? parseInt(next.height, 10)
            : prev;
    }, 0);

    function onClickSelect(pokal) {
        return (e) => {
            getValue = getValue;
            onEmblemShowState(pokal.hasEmblem, pokal);
            onSelect(pokal.value);
        };
    }
    $: {
        if (getValue()) {
            const c = pokals.find((t) => t.value === getValue());
            if (c) {
                onEmblemShowState(c.hasEmblem ? true : false, c);
            }
        }
    }
</script>

<dt
    style="margin: 0!important;padding: 0!important;width: 100%;margin-bottom: 0.5em;"
>
    <label>{title}<em style="color: red">*</em></label>
</dt>

<dd class="default">
    <div class="gui">
        <div class="emblem-no">
            {#each pokals.filter((t) => !t.hasEmblem) as pokal}
                <div
                    on:click={onClickSelect(pokal)}
                    class={`option option-height option-pokal ${
                        getValue() === pokal.value ? "active" : ""
                    }`}
                >
                    <span class="h">{pokal.height} mm</span>
                    <span style="display: flex;height: 100px" class="i">
                        <span
                            class="pokal small"
                            style={`margin-top: auto;height:${Math.round(
                                (parseInt(pokal.height) / maxHeight) * 100
                            )}px`}
                        />
                    </span>
                    <span class="p">
                        ab {Math.floor(parseFloat(pokal.price)).toFixed(0)} €
                    </span>
                </div>
            {/each}
        </div>

        <div class="emblem-yes">
            {#each pokals.filter((t) => t.hasEmblem) as pokal}
                <div
                    on:click={onClickSelect(pokal)}
                    class={`option option-height option-pokal ${
                        getValue() === pokal.value ? "active" : ""
                    }`}
                    data-emblem-size={`${pokal.emblemSize}`}
                >
                    <span class="h">{pokal.height} mm</span>
                    <span style="display: flex;" class="i">
                        <span
                            class={`pokal
                            ${[
                            '21-6291','21-6292','21-6293','21-6294',//magdeburg
                            '21-6321','21-6322','21-6323','21-6324',//kiel
                            '21-6271','21-6272','21-6273','21-6274',//chemnitz
                            '21-6821','21-6822','21-6823','21-6824','21-6825','21-6826','21-6827','21-6828',//rügen
                            '21-6801','21-6802','21-6803','21-6804','21-6805'//sylt
                            ].includes(pokal.sku)?'small':'large'}
                            `}
                            style={`margin-top: auto;height:${Math.round(
                                (parseInt(pokal.height) / maxHeight) * 100
                            )}px`}
                        />
                    </span>
                    <span class="p">
                        ab {Math.floor(parseFloat(pokal.price)).toFixed(0)} €
                    </span>
                </div>
            {/each}
        </div>
    </div>
</dd>

<style>
    span.qty-holder.qty-hide {
        display: none;
    }

    dl.product-option .price-notice {
        display: none;
    }

    .menu {
        overflow: hidden;
    }

    .configurator-step {
        display: none;
    }

    .menu li {
        float: left;
        color: #ccc;
        padding: 10px;
        cursor: pointer;
    }
    .menu li.active {
        background-color: #fff;
        color: #7a868f;
    }

    .menu li span {
        border: 2px solid #eee;
        border-radius: 50%;
        color: #eee;
        font-size: 15px;
        font-weight: 700;
        margin-right: 5px;
        margin-top: -5px;
        padding: 2px 9px;
    }

    .gui {
        overflow: hidden;
    }
    .gui .option {
        float: left;
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 3px;
        margin: 10px;
        text-align: center;
        cursor: pointer;
    }

    .gui .option span {
        display: block;
        padding: 5px;
    }

    .gui .option span.i {
        position: relative;
    }

    .gui .option.option-pokal span.i {
        height: 100px;
    }
    .gui .option.option-emblem span.i {
        height: 50px;
        overflow: hidden;
    }

    .gui .option span.p {
        font-weight: bold;
    }

    .gui .active {
        border-color: green;
        font-weight: bold;
    }

    .gui .emblem-no,
    .gui .emblem-yes {
        display: block;
        float: none;
        clear: both;
    }

    .option.option-height {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .option.option-height .pokal {
        background-size: contain;
        display: inline-block;
        background-position: center bottom;
        background-repeat: no-repeat;
        bottom: 0;
        width: 50px;
    }

    .option.option-height .pokal.small {
        background-image: url(/skin/frontend/smartwave/porto/images/configurator-v2/pokal_ohne_deckel.png);
    }
    .option.option-height .pokal.large {
        background-image: url(/skin/frontend/smartwave/porto/images/configurator-v2/pokal_mit_deckel.png);
    }

    .option.option-height .circle.large {
        font-size: 50px;
        line-height: 25px;
    }

    .option-font .gui ul li,
    .option-color .gui ul li,
    .option-emblem ul li {
        cursor: pointer;
    }

    .option-emblem ul {
        overflow: hidden;
    }
    .option-emblem ul li {
        float: left;
        width: 120px;
        height: 140px;
    }

    .option-color ul {
        overflow: hidden;
    }
    .option-color ul li {
        display: inline-block;
        float: left;
        width: 150px;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        margin: 5px;
    }
    .option-color ul li span {
        display: inline-block;
        height: 24px;
    }

    .option-color ul li span.c {
        width: 24px;
        margin-right: 2px;
        border: 1px solid #ddd;
        border-radius: 3px;
    }

    .option-color ul li span.c {
        opacity: 0.7;
    }
    .option-color ul li span.t {
        opacity: 0.4;
    }
    .option-color ul li.active span.c,
    .option-color ul li.active span.t,
    .option-color ul li:hover span.c,
    .option-color ul li:hover span.t {
        opacity: 1;
    }

    .option-emblem .color span {
        padding: 10px 20px;
        color: #fff;
        font-weight: bold;
        border: 1px solid #ccc;
        border-radius: 4px;
        display: inline-block;
        font-size: 17px;
        margin: 5px;
    }

    .product-bundle-option-20-custom {
        overflow: hidden;
    }

    .product-bundle-option-20-custom li {
        float: left;
        border: 1px solid #ccc;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
    }
</style>
