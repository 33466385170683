export default class OptionAbstract {
    constructor(element) {
        this.element = element;
    }
    getOptionId() {
        var _a;
        return ((_a = this.element.id.toString().match(/\d{3,5}/i)) === null || _a === void 0 ? void 0 : _a[0]) || null;
    }
    getName() {
        return this.element.getAttribute("data-label") || null;
    }
}
