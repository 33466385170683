import OptionAbstract from "../OptionAbstract.js";
export default class OptionColor extends OptionAbstract {
    getValue() {
        var _a;
        return (_a = this.element.querySelector(`#options_${this.getOptionId()}_text`)) === null || _a === void 0 ? void 0 : _a.value;
    }
    setValue(value) {
        return (this.element.querySelector(`#options_${this.getOptionId()}_text`).value = value);
    }
    getHexColor() {
        return this.element.querySelector(`.colors .color.active`).getAttribute("data-hex");
    }
    getType() {
        return "color";
    }
}
