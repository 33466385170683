<script lang="ts">
    export let sizes: Array<{
        name: () => string;
        options: Array<{ name: string; value: string; color: string }>;
        option: Element;
        optionId: () => string;
    }>;

    export let selectedSize: string | undefined;
    export let selectedColorOption: string | undefined;
    export let currentPrice: string | undefined;

    export let onClickSize: (size: any) => void;
    export let onClickColor: (color: any) => void;
</script>

<div style="margin-bottom: 1em;order: -100" id="fleno-option-overlay">
    <h2>
        Größe auswählen<span style="color: red">*</span>:
        <span style="font-weight: bold;">{currentPrice || ""}</span>
    </h2>
    <div
        style="display: flex;width: 100%;gap: 1em;justify-content: flex-start;padding: 0 0 1.5em 0;flex-wrap: wrap"
        id="fleno-sizes-adapter"
    >
        {#each sizes as size}
            <div style="cursor: pointer" on:click={() => onClickSize(size)}>
                <button
                    id="size-btn-{size.optionId()}"
                    style="font-size: 1.5rem;padding: 1.5em;border: 1px solid {size.optionId() ===
                    selectedSize
                        ? 'green'
                        : '#efefef'};"
                    type="button"
                    class="button"
                >
                    {size.name()}
                </button>
            </div>
        {/each}
    </div>

    <h2>
        Farbe auswählen <span style="color: red">*</span>
    </h2>
    <div
        style="display: flex;width: 100%;gap: 1em;justify-content: flex-start;padding: 0 0 1.5em 0;flex-wrap: wrap"
        id="fleno-select-color-adapter"
    >
        {#each sizes.find((t) => t.optionId() === selectedSize)?.options || [] as color}
            <div style="cursor: pointer" on:click={() => onClickColor(color)}>
                <button
                    id="color-btn-{color.value}"
                    style="border: 1px solid {color.value ===
                    selectedColorOption
                        ? 'green'
                        : '#efefef'};min-width: 100px"
                    type="button"
                    class="button"
                >
                    <div
                        style="width: 100%;height: 80px;background-color: {color.color};margin: auto"
                    />
                    <span
                        style="background-color: unset;border: 1px solid rgba(0,0,0,0.5);padding: 0.25em;color: black;line-height: unset;{color.value ===
                        selectedColorOption
                            ? 'border: unset;border-top: 1px solid rgba(0,0,0,0.5)'
                            : 'border: unset;border-top: 1px solid rgba(0,0,0,0)'}"
                    >
                        {color.name}
                    </span>
                </button>
            </div>
        {/each}
    </div>
</div>
