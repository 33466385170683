const $ = jQuery;
export function getOptionIdByName(name, filter = () => true) {
    const found = jQuery("#product-options-wrapper > *")
        .get()
        .find((op) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = jQuery(op)
            .find("dt > label")
            .text()) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.trim().replace(/\*/g, "")) ===
            ((_c = name.toLowerCase()) === null || _c === void 0 ? void 0 : _c.trim().replace(/\*/g, ""))) {
            return filter(op);
        }
        else if (((_e = (_d = jQuery(op)
            .find("h2")
            .text()) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === null || _e === void 0 ? void 0 : _e.trim().replace(/\*/g, "")) ===
            ((_f = name.toLowerCase()) === null || _f === void 0 ? void 0 : _f.trim().replace(/\*/g, ""))) {
            return filter(op);
        }
        return false;
    });
    if (found) {
        const id = jQuery(found).attr("id");
        if (!id) {
            throw new Error("Id is missing as attr.");
        }
        return id;
    }
    return null;
}
export function emblemErrorShower({ emblemParentSelect, optionValueTextUpload, optionValueTextEmblems, rerenderEmblemSwitch, uploadOption, emblemOption, }) {
    if (emblemParentSelect) {
        //@ts-expect-error
        const ll = window.productAddToCartForm.submit;
        //@ts-expect-error
        window.productAddToCartForm.submit = () => {
            const c = $(emblemParentSelect).val();
            let error = null;
            const valueText = $(emblemParentSelect)
                .find(`option[value="${c}"]`)
                .text();
            if (c && valueText) {
                if (optionValueTextUpload(valueText)) {
                    if (!rerenderEmblemSwitch.hasSelectValue(uploadOption)) {
                        error = "Bitte ein Emblem hochladen.";
                    }
                }
                else if (optionValueTextEmblems(valueText)) {
                    if (!rerenderEmblemSwitch.hasSelectValue(emblemOption)) {
                        error = "Bitte ein Emblem auswählen.";
                    }
                }
            }
            $("#fleno-error-msg").remove();
            rerenderEmblemSwitch.showError(false);
            if (!error) {
                ll === null || ll === void 0 ? void 0 : ll();
            }
            else {
                function scrollToMiddle(id) {
                    var elem_position = $(id).offset().top;
                    var window_height = $(window).height();
                    var y = elem_position - window_height / 2;
                    window.scrollTo(0, y);
                }
                rerenderEmblemSwitch.showError(true);
                $("#messages_product_view").prepend(`
                <ul id="fleno-error-msg" class="messages"><li class="error-msg"><ul><li><span>${error}</span></li></ul></li></ul>
                `);
                scrollToMiddle($("#fleno-error-msg"));
            }
        };
    }
}
