import OptionAbstract from "../OptionAbstract.js";
export default class OptionEmblem extends OptionAbstract {
    getValue() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.element
            .querySelector(`#options_${this.getOptionId()}_text`)) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.split("/")) === null || _c === void 0 ? void 0 : _c[1];
    }
    setValue(value) {
        return (this.element.querySelector(`#options_${this.getOptionId()}_text`).value = value);
    }
    getImage() {
        var _a, _b;
        console.log(this.element, (_a = document
            .querySelector(`[data-value="${this.getValue()}"] img`)) === null || _a === void 0 ? void 0 : _a.getAttribute("src"));
        return (_b = this.element
            .querySelector(`[data-value="${this.getValue()}"] img`)) === null || _b === void 0 ? void 0 : _b.getAttribute("src");
    }
    getType() {
        return "emblem";
    }
}
