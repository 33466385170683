import "./config/index.js";
import "./option/emblem/OptionEmblem.js";
import "./option/font/OptionFont.js";
import "./option/ring/OptionColor.js";
import Emblem from "./products/Emblem.js";
import Figuren from "./products/Figuren.js";
import GravurschildScharpe from "./products/GravurschildScharpe.js";
import Medaillen from "./products/Medaillen.js";
import Plakette from "./products/Plakette.js";
import PokalAachen from "./products/Pokal-Aachen.js";
import Scharpen from "./products/Scharpen.js";
import Turnierschleife from "./products/Turnierschleife.js";
const $ = jQuery;
async function bootstrap(config) {
    if (config.configuratorType === "turnierschleife")
        Turnierschleife();
    if (config.configuratorType === "Schärpen")
        Scharpen();
    if (config.configuratorType === "GravurschildScharpe")
        GravurschildScharpe();
    if (config.configuratorType === "Pokal")
        PokalAachen();
    if (config.configuratorType === "Figuren")
        Figuren();
    if (config.configuratorType === "Medaillen")
        Medaillen();
    if (config.configuratorType === "Plakette")
        Plakette();
    if (config.configuratorType === "Emblem")
        Emblem();
    const reloadPrice = (bundleConfig, bundleQuantity) => {
        let calculatedPrice = 0;
        let dispositionPrice = 0;
        let includeTaxPrice = 0;
        //@ts-expect-error
        let taxCalcMethod = window.taxCalcMethod;
        //@ts-expect-error
        let CACL_UNIT_BASE = window.CACL_UNIT_BASE;
        //@ts-expect-error
        let CACL_TOTAL_BASE = window.CACL_TOTAL_BASE;
        //@ts-expect-error
        let optionsPrice = window.optionsPrice;
        const selected = bundleConfig.selected;
        const options = bundleConfig.options;
        for (const optionId in options) {
            for (const selectionId in options[optionId].selections) {
                if (selected[optionId].includes(selectionId)) {
                    const res = getPriceFor(optionId, selectionId, bundleConfig, bundleQuantity);
                    calculatedPrice += Number(res[0]);
                    dispositionPrice += Number(res[1]);
                    includeTaxPrice += Number(res[2]);
                }
            }
        }
        if (taxCalcMethod == CACL_TOTAL_BASE) {
            let calculatedPriceFormatted = calculatedPrice.toFixed(10);
            let includeTaxPriceFormatted = includeTaxPrice.toFixed(10);
            let tax = includeTaxPriceFormatted - calculatedPriceFormatted;
            calculatedPrice = includeTaxPrice - Math.round(tax * 100) / 100;
        }
        let event = $(document).trigger('bundle:reload-price', {
            price: calculatedPrice,
            priceInclTax: includeTaxPrice,
            dispositionPrice: dispositionPrice,
            bundle: this
        });
        if (!event.noReloadPrice) {
            optionsPrice.specialTaxPrice = 'true';
            optionsPrice.changePrice('bundle', calculatedPrice);
            optionsPrice.changePrice('nontaxable', dispositionPrice);
            optionsPrice.changePrice('priceInclTax', includeTaxPrice);
            optionsPrice.reload();
        }
        return calculatedPrice;
    };
    const getPriceFor = (optionId, selectionId, bundleConfig, bundleQuantity) => {
        let qty = 1; //no individual quantities
        let selection = bundleConfig.options[optionId].selections[selectionId];
        let price = bundleConfig.options[optionId].selections[selectionId].price;
        let priceInclTax;
        let tierPriceInclTax, tierPriceExclTax;
        const tierPrice = bundleConfig.options[optionId].selections[selectionId].tierPrice;
        const priceType = bundleConfig.priceType;
        if (priceType == '0') {
            for (const tier in tierPrice) {
                if (bundleQuantity >= Number(tierPrice[tier].price_qty) && price > Number(tierPrice[tier].price)) {
                    price = tierPrice[tier].price;
                    tierPriceInclTax = tierPrice[tier].priceInclTax;
                    tierPriceExclTax = tierPrice[tier].priceExclTax;
                }
            }
        }
        else {
            if (selection.priceType == '0') {
                price = selection.priceValue;
            }
            else {
                price = (bundleConfig.basePrice * selection.priceValue) / 100;
            }
        }
        let disposition = selection.plusDisposition + selection.minusDisposition;
        if (bundleConfig.specialPrice) {
            const newPrice = (price * bundleConfig.specialPrice) / 100;
            price = Math.min(newPrice, price);
        }
        if (tierPriceInclTax !== undefined && tierPriceExclTax !== undefined) {
            priceInclTax = tierPriceInclTax;
            price = tierPriceExclTax;
        }
        else if (selection.priceInclTax !== undefined) {
            priceInclTax = selection.priceInclTax;
            price = selection.priceExclTax !== undefined ? selection.priceExclTax : selection.price;
        }
        else {
            priceInclTax = price;
        }
        let result;
        //@ts-expect-error
        let taxCalcMethod = window.taxCalcMethod;
        //@ts-expect-error
        let CACL_UNIT_BASE = window.CACL_UNIT_BASE;
        //@ts-expect-error
        let CACL_TOTAL_BASE = window.CACL_TOTAL_BASE;
        if (bundleConfig.priceType == '1' || taxCalcMethod == CACL_TOTAL_BASE) {
            result = [price * qty, disposition * qty, priceInclTax * qty];
        }
        else if (taxCalcMethod == CACL_UNIT_BASE) {
            price = (Math.round(price * 100) / 100).toString();
            disposition = (Math.round(disposition * 100) / 100).toString();
            priceInclTax = (Math.round(priceInclTax * 100) / 100).toString();
            result = [price * qty, disposition * qty, priceInclTax * qty];
        }
        else { //taxCalcMethod == CACL_ROW_BASE)
            price = (Math.round(price * qty * 100) / 100).toString();
            disposition = (Math.round(disposition * qty * 100) / 100).toString();
            priceInclTax = (Math.round(priceInclTax * qty * 100) / 100).toString();
            result = [price, disposition, priceInclTax];
        }
        return result;
    };
    const updatePrice = () => requestAnimationFrame(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        //@ts-expect-error
        let optionsPrice = window.optionsPrice;
        let bundleQuantity = 0;
        //@ts-expect-error
        (_b = (_a = window === null || window === void 0 ? void 0 : window.bundle) === null || _a === void 0 ? void 0 : _a.reloadPrice) === null || _b === void 0 ? void 0 : _b.call(_a);
        //@ts-expect-error
        if (((_c = window === null || window === void 0 ? void 0 : window.opConfig) === null || _c === void 0 ? void 0 : _c.reloadPrice) instanceof Function) {
            //@ts-expect-error
            window.opConfig.reloadPrice();
        }
        let ogPrice = Math.round(100 *
            parseFloat((_f = (_e = (_d = $(".full-product-price > .price")) === null || _d === void 0 ? void 0 : _d.get(0)) === null || _e === void 0 ? void 0 : _e.innerText) === null || _f === void 0 ? void 0 : _f.replace("€", "").replace(",", "."))) / 100;
        //@ts-expect-error
        if (((_h = (_g = window === null || window === void 0 ? void 0 : window.bundle) === null || _g === void 0 ? void 0 : _g.config) === null || _h === void 0 ? void 0 : _h.quantitySelector) && ((_k = (_j = window === null || window === void 0 ? void 0 : window.bundle) === null || _j === void 0 ? void 0 : _j.config) === null || _k === void 0 ? void 0 : _k.options) && ((_m = (_l = window === null || window === void 0 ? void 0 : window.bundle) === null || _l === void 0 ? void 0 : _l.config) === null || _m === void 0 ? void 0 : _m.selected)) {
            //@ts-expect-error
            const bundleConfig = window.bundle.config;
            const quantitySelector = bundleConfig.quantitySelector;
            //@ts-expect-error
            if (!bundleConfig.quantityElement)
                bundleQuantity = $(quantitySelector).val();
            //@ts-expect-error
            reloadPrice(window.bundle.config, bundleQuantity);
            //TODO determine if price is with or without tax...
            if (optionsPrice.showIncludeTax) {
                ogPrice = optionsPrice.optionPrices['priceInclTax'];
            }
            else {
                ogPrice = (_p = (_o = optionsPrice.optionPrices['bundle']) !== null && _o !== void 0 ? _o : optionsPrice.optionPrices['priceInclTax']) !== null && _p !== void 0 ? _p : ogPrice;
            }
        }
        let price = ogPrice;
        const qty = bundleQuantity;
        if (!ogPrice || !qty) {
            return;
        }
        [...$(".tier-prices.product-pricing .benefit").get()].forEach((t) => {
            var _a, _b, _c;
            const discount = parseInt((_a = $(t)
                .text()
                .match(/\d{0,}/)) === null || _a === void 0 ? void 0 : _a[0], 10);
            const amount = parseInt((_c = (_b = $(t)
                .parent()
                .text()
                .match(/^Kaufe\ (?<amount>\d{0,})/)) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.amount, 10);
            const tmpPrice = Math.round(ogPrice * (1 - discount / 100) * 100) / 100;
            if (qty >= amount) {
                price = tmpPrice;
                $(".full-product-price > .price").text(`${price.toFixed(2).replace(".", ",")} €`);
            }
        });
        if (document.querySelector(".total-fleno-price-box")) {
            $(".total-fleno-price-box").text(`${(qty * price).toFixed(2).replace(".", ",")} €`);
        }
    });
    jQuery(".product-options-wrapper-configurator, .product-options-wrapper, .product-options-bottom")
        .on("click", updatePrice)
        .on("change", updatePrice)
        .on("blur", updatePrice)
        .on("keyup", updatePrice);
    updatePrice();
}
//@ts-expect-error
window.initConfiguratorv2 = bootstrap;
