<script lang="ts">
    export let sizes: Array<{
        optionId: string;
        options?: Array<{ value: string; color: string }>;
        sizeName: string;
    }>;
    export let selectedSize: string | undefined;
    export let selectedColorOption: string | undefined;

    export let onClickSize: (size: any) => void;
    export let onClickColor: (color: any) => void;
</script>

<div style="margin-bottom: 1em;order: -100" id="fleno-option-overlay">
    <h2>
        Größe <span style="color: red">*</span>
    </h2>
    <div
        style="display: flex;width: 100%;gap: 1em;justify-content: space-around;padding: 0 0 1.5em 0;flex-wrap: wrap"
        id="fleno-sizes-adapter"
    >
        {#each sizes as size}
            <div on:click={() => onClickSize(size)}>
                <button
                    id="size-btn-{size.optionId}"
                    style="font-size: 1.5rem;padding: 2em;border: 1px solid {size.optionId ===
                    selectedSize
                        ? 'green'
                        : '#efefef'};"
                    type="button"
                    class="button"
                >
                    {size.sizeName}
                </button>
            </div>
        {/each}
    </div>

    <h2>
        Farbe <span style="color: red">*</span>
    </h2>
    <div
        style="display: flex;width: 100%;gap: 1em;justify-content: flex-start;padding: 0 0 1.5em 0;flex-wrap: wrap"
        id="fleno-select-color-adapter"
    >
        {#each sizes.find((t) => t.optionId === selectedSize)?.options || [] as color}
            <div on:click={() => onClickColor(color)}>
                <button
                    id="color-btn-{color.value}"
                    style="border: 1px solid {color.value ===
                    selectedColorOption
                        ? 'green'
                        : '#efefef'};"
                    type="button"
                    class="button"
                >
                    <img
                        height="130px"
                        width="130px"
                        alt={color.color}
                        src="/skin/frontend/smartwave/porto/images/configurator-v2/Schärpen/{color.color}.jpg"
                    />
                    <span
                        style="background-color: unset;border: 1px solid rgba(0,0,0,0.5);padding: 0.25em;color: black;line-height: unset;{color.value ===
                        selectedColorOption
                            ? 'border: unset;border-top: 1px solid rgba(0,0,0,0.5)'
                            : 'border: unset;border-top: 1px solid rgba(0,0,0,0)'}"
                    >
                        {color.color}
                    </span>
                </button>
            </div>
        {/each}
    </div>
</div>
