import { emblemErrorShower, getOptionIdByName } from "../utils.js";
import FontTextWrapper from "../Wrapper/FontTextWrapper.js";
import PokalSelect from "../components/PokalSelect.svelte";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher.js";
const $ = window.jQuery;
export default function PokalAachen() {
    var _a;
    const option = getOptionIdByName("BESCHRIFTUNG");
    const fontTypeOption = document.querySelector("#" + option);
    const options = fontTypeOption.querySelectorAll("select option");
    let subText = `Textgravur auf Sockel +`;
    for (const opt of options) {
        if (!opt.getAttribute("data-price_currency_symbol"))
            continue;
        if (opt.getAttribute("data-price_including_tax_formatted"))
            subText += opt.getAttribute("data-price_including_tax_formatted");
        else
            subText += opt.getAttribute("data-price_formatted");
        subText += opt.getAttribute("data-price_currency_symbol");
    }
    const c = FontTextWrapper({
        fontOption: document.querySelector(`#${getOptionIdByName("Schriftart")}`),
        textOption: document.querySelector(`#${getOptionIdByName("TEXTGRAVUR AUF EINER PLAKETTE")}`),
        fontTypeOption: fontTypeOption,
        target: document.querySelector("#product-options-wrapper"),
        order: 145,
        labelOverwrite: "TEXTGRAVUR",
        subText: subText,
    });
    c.render();
    const emblemOwners = [
        {
            name: "EMBLEM",
            option: document.querySelector(`#${getOptionIdByName("EMBLEM")}`),
            upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
            emblems: document.querySelector(`#${getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))}`),
        },
        {
            name: "EMBLEM OBEN",
            option: document.querySelector(`#${getOptionIdByName("EMBLEM OBEN")}`),
            upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN OBEN")}`),
            emblems: document.querySelector(`#${getOptionIdByName("Emblem oben", (op) => $(op).hasClass("option-emblem-root"))}`),
        },
        {
            name: "EMBLEM UNTEN",
            option: document.querySelector(`#${getOptionIdByName("EMBLEM UNTEN")}`),
            upload: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN unten")}`),
            emblems: document.querySelector(`#${getOptionIdByName("Emblem unten", (op) => {
                return $(op).hasClass("option-emblem-root");
            })}`),
        },
    ].filter((c) => Object.values(c).filter(Boolean).length === Object.values(c).length);
    const pokalOptionId = getOptionIdByName("HÖHE DES POKALS");
    const pokalSelectId = "A" + (Math.random() * 100000).toFixed(0);
    $("#product-options-wrapper").append($(`<div style="order: 0" id='${pokalSelectId}'></div>`));
    console.log($(`#${pokalOptionId} option`).get());
    new PokalSelect({
        target: document.querySelector(`#${pokalSelectId}`),
        props: {
            onSelect: (val) => $(`#${pokalOptionId} .bundle-option-select`)
                .val(val)
                .trigger("change")
                .trigger("click"),
            pokals: [...$(`#${pokalOptionId} option`).get()]
                .map((t) => ({
                value: $(t).attr("value"),
                name: $(t).attr("data-name"),
                hasEmblem: $(t).attr("data-emblem_size_value")
                    ? true
                    : false,
                emblemSize: $(t).attr("data-emblem_size"),
                height: $(t).attr("data-height_value"),
                price: $(t).attr("data-price_including_tax"),
                sku: $(t).attr("data-sku"),
            }))
                .filter((t) => t.value),
            getValue: () => $(`#${pokalOptionId} .bundle-option-select`).val(),
            onEmblemShowState: (active, pokal) => {
                const emblemSizeOben = ["474", "469"];
                for (const { option: emblemOwner, name } of emblemOwners) {
                    const doubleDisable = name === "EMBLEM OBEN" &&
                        !emblemSizeOben.includes(pokal.emblemSize);
                    if (!active || doubleDisable) {
                        $(emblemOwner)
                            .find("select")
                            .val("")
                            .trigger("change")
                            .trigger("click");
                        $(emblemOwner).css("display", "none");
                    }
                    else {
                        $(emblemOwner).css("display", "");
                    }
                }
            },
        },
    });
    $(`#${pokalOptionId}`).css("display", "none");
    const optionValueTextUpload = (s) => s.includes("Eigenes Motiv");
    const optionValueTextEmblems = (s) => s.includes("mm");
    const rerenderEmblemSwitch = EmblemSwitcher(emblemOwners);
    for (const { emblems, option, upload } of emblemOwners) {
        console.log("emblemErrorShower");
        console.log(emblems, option, upload);
        console.log(getOptionIdByName("Emblem"));
        console.log("-----emblemErrorShower");
        if (emblems && option && upload) {
            emblemErrorShower({
                emblemParentSelect: $(option)
                    .find("select.bundle-option-select")
                    .get(0),
                optionValueTextEmblems,
                optionValueTextUpload,
                rerenderEmblemSwitch,
                uploadOption: document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`),
                emblemOption: document.querySelector(`#${(_a = getOptionIdByName("Emblem", (op) => $(op).hasClass("option-emblem-root"))) !== null && _a !== void 0 ? _a : getOptionIdByName("Emblem unten", (op) => $(op).hasClass("option-emblem-root"))}`),
            });
        }
    }
}
