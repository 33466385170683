import { emblemErrorShower, getOptionIdByName } from "../utils";
import EmblemSwitcher from "../Wrapper/EmblemSwitcher";
import FontTextWrapper from "../Wrapper/FontTextWrapper";
import SelectBandKordel from "../components/SelectBandKordel.svelte";
const $ = jQuery;
export default function Medaillen() {
    const sId = getOptionIdByName("AUSWAHL");
    if (sId) {
        jQuery(`#${sId}`).css("display", "none");
    }
    const optionValueTextUpload = (s) => s.includes("Eigenes Motiv");
    const optionValueTextEmblems = (s) => s.includes("mm");
    const emblemParentSelectRoot = $(document.querySelector(`#${getOptionIdByName("EMBLEM", (op) => $(op).hasClass("bundle-option-select"))}`)).get(0);
    if (emblemParentSelectRoot) {
        const emblemParentSelect = $(emblemParentSelectRoot)
            .find("select.bundle-option-select")
            .get(0);
        const uploadOption = document.querySelector(`#${getOptionIdByName("EIGENES EMBLEM HOCHLADEN")}`);
        const emblemsOption = document.querySelector(`#${getOptionIdByName("Emblem Wahl", (op) => $(op).hasClass("option-emblem-root"))}`);
        let rerenderEmblemSwitch = emblemParentSelect
            ? EmblemSwitcher([
                {
                    option: emblemParentSelectRoot,
                    upload: uploadOption,
                    emblems: emblemsOption,
                },
            ], optionValueTextUpload, optionValueTextEmblems)
            : null;
        rerenderEmblemSwitch === null || rerenderEmblemSwitch === void 0 ? void 0 : rerenderEmblemSwitch.render();
        if (emblemParentSelect) {
            emblemErrorShower({
                emblemParentSelect,
                optionValueTextEmblems,
                optionValueTextUpload,
                rerenderEmblemSwitch,
                uploadOption,
                emblemOption: emblemsOption,
            });
        }
    }
    if (document.querySelector(`#${getOptionIdByName("Schriftart")}`)) {
        const c = FontTextWrapper({
            fontOption: document.querySelector(`#${getOptionIdByName("Schriftart")}`),
            textOption: document.querySelector(`#${getOptionIdByName("TEXTGRAVUR")}`),
            fontTypeOption: document.querySelector(`#${getOptionIdByName("Beschriftung")}`),
            target: document.querySelector("#product-options-wrapper"),
            order: 140,
            labelOverwrite: "Beschriftung",
            subText: [
                ...document
                    .querySelector(`#${getOptionIdByName("Beschriftung")}`)
                    .querySelectorAll("option"),
            ]
                .filter((t) => $(t).attr("value"))
                .map((t) => t.textContent)
                .join(","),
        });
        c.render();
    }
    if (document.querySelector(`#${getOptionIdByName("Schriftart2")}`)) {
        const c = FontTextWrapper({
            fontOption: document.querySelector(`#${getOptionIdByName("Schriftart2")}`),
            textOption: document.querySelector(`#${getOptionIdByName("TEXTGRAVUR2")}`),
            fontTypeOption: document.querySelector(`#${getOptionIdByName("Beschriftung2")}`),
            target: document.querySelector("#product-options-wrapper"),
            order: 145,
            labelOverwrite: "Beschriftung hinten",
            subText: [
                ...document
                    .querySelector(`#${getOptionIdByName("Beschriftung2")}`)
                    .querySelectorAll("option"),
            ]
                .filter((t) => $(t).attr("value"))
                .map((t) => t.textContent)
                .join(","),
        });
        c.render();
    }
    const bandKordelOptionId = getOptionIdByName("ZUBEHÖR BAND/KORDEL");
    if (bandKordelOptionId) {
        const options = [
            ...document.querySelectorAll(`#${bandKordelOptionId} select option`),
        ]
            .map((t) => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                name: (_c = (_b = (_a = $(t).attr("data-name")) === null || _a === void 0 ? void 0 : _a.match(/^(?<type>[A-Za-zÄÖÜäöüß]{1,})\s/i)) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.type,
                color: (_f = (_e = (_d = $(t).attr("data-name")) === null || _d === void 0 ? void 0 : _d.match(/\s(?<color>[A-Za-zÄÖÜäöüß]{1,}\-{0,}[A-Za-zÄÖÜäöüß]{0,}\-{0,}[A-Za-zÄÖÜäöüß]{0,})$/i)) === null || _e === void 0 ? void 0 : _e.groups) === null || _f === void 0 ? void 0 : _f.color,
                option: t,
                price: $(t).attr("data-final_price"),
                value: $(t).attr("value"),
            });
        })
            .filter((t) => t.value && t.color && t.name);
        jQuery(`#${bandKordelOptionId}`).css("display", "none");
        new SelectBandKordel({
            target: document.querySelector("#product-options-wrapper"),
            props: {
                colors: options,
                title: jQuery(`#${bandKordelOptionId} dt > label`).text(),
            },
        });
    }
}
