const $ = jQuery;
export default function EmblemSwitcher(emblemOwners, isUpload = (s) => s.includes("Eigenes Motiv"), isEmblem = (s) => s.includes("mm"), isUpload2 = (s) => !s.includes("Laminat-Emblem (") && s.includes("Sublimationsemblem")) {
    const c = new Map();
    let fns = [];
    for (const [index, { option: emblemOwner, emblems, upload },] of Object.entries(emblemOwners)) {
        if (!emblemOwner || !emblems || !upload) {
            console.warn("missing index", index, {
                emblemOwner,
                emblems,
                upload,
            });
            continue;
        }
        const renderEmblemOptions = () => {
            var _a;
            const i = (parseInt(index) + 1) * 10;
            const v = (_a = $([...emblemOwner.querySelectorAll("select option")].find((t) => $(t).attr("value") ===
                $(emblemOwner.querySelectorAll("select")).val()))) === null || _a === void 0 ? void 0 : _a.text();
            $(emblemOwner).css("order", (i + 1).toString());
            $(emblems).css("order", (i + 2).toString());
            $(upload).css("order", (i + 3).toString());
            $(emblems).css("display", "none");
            $(upload).css("display", "none");
            $(upload).find(".fleno-info-text").remove();
            if ((isUpload2 === null || isUpload2 === void 0 ? void 0 : isUpload2(v)) || isUpload(v)) {
                $(upload).append($(`<div style="font-weight: 800;color: red" class="fleno-info-text"><p>Zusätzlich zur Bestellbestätigung erhalten Sie in Kürze ein unverbindliches Preisangebot für das angefragte Laminat-Emblem.</p></div>`));
            }
            if (isUpload(v)) {
                $(emblems).find("input").val("");
                $(upload).css("display", "");
                c.set(upload, () => $(upload).find("input").val());
            }
            else if (isEmblem(v)) {
                $(upload).find("input").val("");
                $(emblems).css("display", "");
                c.set(emblems, () => $(emblems).find("input").val());
            }
        };
        $(emblemOwner.querySelector("select")).on("click", renderEmblemOptions);
        renderEmblemOptions();
        fns.push(renderEmblemOptions);
    }
    return {
        render: () => fns.map((t) => t()),
        hasSelectValue: (o) => {
            var _a, _b;
            return (_b = (_a = c.get(o)) === null || _a === void 0 ? void 0 : _a()) !== null && _b !== void 0 ? _b : false;
        },
        showError: (state) => {
            for (const { emblems, option, upload } of emblemOwners) {
                if (state) {
                    $(option).css("border", "2px solid red");
                }
                else {
                    $(option).css("border", "");
                }
            }
        },
    };
}
