import { getOptionIdByName } from "../utils.js";
import "../Wrapper/FontTextWrapper.js";
import "../Wrapper/EmblemSwitcher.js";
import GravurschildScharpeSelector from "../components/GravurschildScharpeSelector.svelte";
import config from "../config/index.js";
const $ = jQuery;
export default function GravurschildScharpe() {
    const sizes = [
        getOptionIdByName("45X15MM"),
        getOptionIdByName("55X15MM"),
        getOptionIdByName("55X25MM"),
        getOptionIdByName("60X30MM"),
        getOptionIdByName("75X35MM"),
        getOptionIdByName("25 MM X 50 M"),
        getOptionIdByName("40 MM X 50 M"),
    ]
        .map((t) => t && document.querySelector(`#${t}`))
        .filter(Boolean);
    jQuery(".product-options .qty-holder").css("display", "none");
    const realSizes = [];
    for (const size of sizes) {
        $(size).css("display", "none");
        realSizes.push({
            name: () => $(size).find("dt > label").text(),
            option: size,
            options: [...$(size).find("select > option").get()]
                .map((t) => {
                var _a, _b;
                return ({
                    name: $(t)
                        .text()
                        .match(/[äöüÄÖÜß\w]{0,}/)[0],
                    value: $(t).attr("value"),
                    color: ((_a = config.colorMap.find((c) => { var _a; return (_a = $(t).text()) === null || _a === void 0 ? void 0 : _a.includes(c.match); })) === null || _a === void 0 ? void 0 : _a.color) || "yellow",
                    price: ((_b = $(t)
                        .text()
                        .match(/\+\d{1,}\,\d{1,}/)) === null || _b === void 0 ? void 0 : _b[0]) + " €" || "",
                });
            })
                .filter((t) => t.value),
            optionId: () => $(size).attr("id"),
        });
    }
    console.log(realSizes);
    let selectedSize = realSizes[0].option.id;
    let selectedColorOption = realSizes[0].options[0].value;
    const gravurschildScharpeSelector = new GravurschildScharpeSelector({
        target: document.querySelector(`#product-options-wrapper`),
        props: {
            sizes: realSizes,
            selectedSize,
            selectedColorOption,
            onClickSize: (size) => {
                selectedSize = size.optionId();
                render();
            },
            onClickColor: (size) => {
                selectedColorOption = size.value;
                render();
            },
            currentPrice: undefined,
        },
    });
    function clearValuesOfSizes() {
        for (const size of realSizes) {
            jQuery(`#bundle-option-${size.optionId().replace("option-", "")}`)
                .val("")
                .trigger("change")
                .trigger("click");
        }
    }
    function render() {
        var _a, _b;
        clearValuesOfSizes();
        gravurschildScharpeSelector.$set({
            sizes: realSizes,
            selectedSize,
            selectedColorOption,
            onClickSize: (size) => {
                var _a, _b;
                if (selectedSize != size.optionId()) {
                    selectedColorOption = (_b = (_a = size === null || size === void 0 ? void 0 : size.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value;
                }
                selectedSize = size.optionId();
                render();
            },
            onClickColor: (size) => {
                selectedColorOption = size.value;
                render();
            },
            currentPrice: (_b = (_a = realSizes
                .find((t) => t.optionId() === selectedSize)) === null || _a === void 0 ? void 0 : _a.options.find((t) => t.value === selectedColorOption)) === null || _b === void 0 ? void 0 : _b.price,
        });
        for (const size of realSizes) {
            if (size.optionId() === selectedSize && selectedColorOption) {
                jQuery(`#bundle-option-${size.optionId().replace("option-", "")}`)
                    .val(selectedColorOption)
                    .trigger("change")
                    .trigger("click");
            }
        }
    }
    render();
}
